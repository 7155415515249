var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap doc_req_pop", staticStyle: { width: "950px" } },
    [
      _c("form", { attrs: { id: "frm" } }, [
        _c(
          "button",
          {
            staticClass: "layer_close",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("span", [_vm._v("close")])]
        ),
        _c(
          "div",
          { staticClass: "popup_cont" },
          [
            _c("h1", { staticClass: "page_title text_center" }, [
              _vm._v(" 컨테이너 반출 요청서 "),
            ]),
            _c("div", { staticClass: "content_box" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 1. 요청 개요 "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" 요청일 "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.today)),
                    ]),
                    _c("td"),
                  ]),
                  _vm._m(1),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "text_center", attrs: { rowspan: "2" } },
                      [_vm._v(" 발신 ")]
                    ),
                    _c("td", [_vm._v(_vm._s(_vm.formData.shprCstEnm))]),
                    _c("td", [_vm._v(_vm._s(_vm.formData.shprCstNm))]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.shprCstTelNo,
                            expression: "formData.shprCstTelNo",
                          },
                        ],
                        attrs: {
                          id: "shprCstTelNo",
                          type: "text",
                          placeholder: "Tel",
                          maxlength: "100",
                        },
                        domProps: { value: _vm.formData.shprCstTelNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formData,
                              "shprCstTelNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.shprCstEml,
                            expression: "formData.shprCstEml",
                          },
                        ],
                        attrs: {
                          id: "shprCstEml",
                          type: "text",
                          placeholder: "E-mail",
                          maxlength: "100",
                        },
                        domProps: { value: _vm.formData.shprCstEml },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formData,
                                "shprCstEml",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkErrorVisible("shprCstEml")
                            },
                          ],
                          blur: function ($event) {
                            return _vm.checkValidationEmail("shprCstEml")
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "text_center", attrs: { rowspan: "2" } },
                      [_vm._v(" 실화주명 ")]
                    ),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.formData.actShprCstEnm)),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 2. Booking 정보 "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(2),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" BKG No. "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(" " + _vm._s(_vm.formData.bkgNo) + " "),
                    ]),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" POL/POD "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formData.polPlcNm) +
                          " / " +
                          _vm._s(_vm.formData.podPlcNm)
                      ),
                    ]),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" VSL/VOY "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        _vm._s(_vm.formData.vslNm) +
                          " / " +
                          _vm._s(_vm.formData.voyNo)
                      ),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 3. 요청 상세 "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _c(
                  "tbody",
                  [
                    _c("tr", [
                      _c("th", { staticClass: "text_center" }, [
                        _vm._v(" 반출 터미널 "),
                      ]),
                      _c("td", { attrs: { colspan: "6" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.outTrmlCd,
                              expression: "formData.outTrmlCd",
                            },
                          ],
                          attrs: {
                            id: "outTrmlCd",
                            type: "text",
                            maxlength: "200",
                            placeholder: "반출하고자 하는 터미널 이름",
                          },
                          domProps: { value: _vm.formData.outTrmlCd },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "outTrmlCd",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#outTrmlCd"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { staticClass: "text_center" }, [
                        _vm._v(" 반출 사유 "),
                      ]),
                      _c("td", { attrs: { colspan: "6" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.reqCont,
                              expression: "formData.reqCont",
                            },
                          ],
                          attrs: {
                            id: "reqCont",
                            type: "text",
                            maxlength: "2000",
                            placeholder: "사유 입력",
                          },
                          domProps: { value: _vm.formData.reqCont },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "reqCont",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#reqCont"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { staticClass: "text_center" }, [
                        _vm._v(" 반출 예정일 "),
                      ]),
                      _c("td", { attrs: { colspan: "4" } }, [
                        _c(
                          "div",
                          { staticClass: "input_calendar" },
                          [
                            _c("e-date-picker", {
                              attrs: { id: "outReqDt" },
                              on: {
                                input: function ($event) {
                                  _vm.fnValidationEtd(),
                                    _vm.$ekmtcCommon.hideErrorTooltip(
                                      "#outReqDt"
                                    )
                                },
                              },
                              model: {
                                value: _vm.formData.outReqDt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "outReqDt", $$v)
                                },
                                expression: "formData.outReqDt",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { staticClass: "text_center" }, [
                        _vm._v(" 운송회사 "),
                      ]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.tfcoNm,
                              expression: "formData.tfcoNm",
                            },
                          ],
                          attrs: {
                            id: "tfcoNm",
                            type: "text",
                            placeholder: "업체명",
                            maxlength: "100",
                          },
                          domProps: { value: _vm.formData.tfcoNm },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "tfcoNm",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#tfcoNm"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.tfcoPicNm,
                              expression: "formData.tfcoPicNm",
                            },
                          ],
                          attrs: {
                            id: "tfcoPicNm",
                            type: "text",
                            placeholder: "담당자",
                            maxlength: "100",
                          },
                          domProps: { value: _vm.formData.tfcoPicNm },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "tfcoPicNm",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#tfcoPicNm"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.tfcoTelNo,
                              expression: "formData.tfcoTelNo",
                            },
                          ],
                          attrs: {
                            id: "tfcoTelNo",
                            type: "text",
                            placeholder: "전화번호",
                            maxlength: "100",
                          },
                          domProps: { value: _vm.formData.tfcoTelNo },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "tfcoTelNo",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#tfcoTelNo"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { staticClass: "text_center" }, [
                        _vm._v(" 상하차료정산 "),
                      ]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.tbcNm,
                              expression: "formData.tbcNm",
                            },
                          ],
                          attrs: {
                            id: "tbcNm",
                            type: "text",
                            placeholder: "업체명",
                            maxlength: "100",
                          },
                          domProps: { value: _vm.formData.tbcNm },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "tbcNm",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#tbcNm"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.tbcPicNm,
                              expression: "formData.tbcPicNm",
                            },
                          ],
                          attrs: {
                            id: "tbcPicNm",
                            type: "text",
                            placeholder: "담당자",
                            maxlength: "100",
                          },
                          domProps: { value: _vm.formData.tbcPicNm },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "tbcPicNm",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#tbcPicNm"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.tbcTelNo,
                              expression: "formData.tbcTelNo",
                            },
                          ],
                          attrs: {
                            id: "tbcTelNo",
                            type: "text",
                            placeholder: "전화번호",
                            maxlength: "100",
                          },
                          domProps: { value: _vm.formData.tbcTelNo },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "tbcTelNo",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#tbcTelNo"
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { staticClass: "text_center" }, [
                        _vm._v(" 추가선적명세 "),
                      ]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.addShipPkgCd,
                              expression: "formData.addShipPkgCd",
                            },
                          ],
                          attrs: {
                            id: "addShipPkgCd",
                            type: "text",
                            placeholder: "품명",
                            maxlength: "200",
                          },
                          domProps: { value: _vm.formData.addShipPkgCd },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "addShipPkgCd",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#addShipPkgCd"
                                ),
                                  _vm.$ekmtcCommon.onlyEngNumber($event)
                              },
                            ],
                            keyup: function ($event) {
                              return _vm.$ekmtcCommon.changeUpperCase($event)
                            },
                          },
                        }),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-input-number", {
                            attrs: {
                              id: "addShipPkgQty",
                              type: "text",
                              placeholder: "수량",
                              maxlength: "10",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#addShipPkgQty"
                                )
                              },
                            },
                            model: {
                              value: _vm.formData.addShipPkgQty,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "addShipPkgQty", $$v)
                              },
                              expression: "formData.addShipPkgQty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("e-input-number", {
                            attrs: {
                              id: "addShipWt",
                              type: "text",
                              placeholder: "중량",
                              maxlength: "10",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#addShipWt"
                                )
                              },
                            },
                            model: {
                              value: _vm.formData.addShipWt,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "addShipWt", $$v)
                              },
                              expression: "formData.addShipWt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _c("e-input-number", {
                            attrs: {
                              id: "addShipAmt",
                              type: "text",
                              placeholder: "화물가액",
                              maxlength: "10",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$ekmtcCommon.hideErrorTooltip(
                                  "#addShipAmt"
                                )
                              },
                            },
                            model: {
                              value: _vm.formData.addShipAmt,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "addShipAmt", $$v)
                              },
                              expression: "formData.addShipAmt",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._l(_vm.containerInfo, function (vo, idx) {
                      return _c("tr", { key: "cntr_" + idx }, [
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(" CNTR No. "),
                        ]),
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cntrNo,
                                expression: "vo.cntrNo",
                              },
                            ],
                            staticStyle: { width: "150px" },
                            attrs: {
                              id: "cntrNo_" + idx,
                              type: "text",
                              maxlength: "11",
                            },
                            domProps: { value: vo.cntrNo },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "cntrNo", $event.target.value)
                                },
                                function ($event) {
                                  return _vm.changeOnlyUpperEng(
                                    $event,
                                    vo,
                                    "cntrNo",
                                    idx
                                  )
                                },
                              ],
                              blur: function ($event) {
                                return _vm.checkValidationCntrNo(idx)
                              },
                            },
                          }),
                          idx === 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addBLContainer(idx)
                                    },
                                  },
                                },
                                [_vm._v(" plus ")]
                              )
                            : _vm._e(),
                          idx !== 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeBLContainer(idx)
                                    },
                                  },
                                },
                                [_vm._v(" minus ")]
                              )
                            : _vm._e(),
                        ]),
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(" TYPE "),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cntrTypCd,
                                expression: "vo.cntrTypCd",
                              },
                            ],
                            attrs: {
                              id: "cntrTypCd_" + idx,
                              type: "text",
                              disabled: "",
                            },
                            domProps: { value: vo.cntrTypCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "cntrTypCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(" SIZE "),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cntrSzCd,
                                expression: "vo.cntrSzCd",
                              },
                            ],
                            attrs: {
                              id: "cntrSzCd_" + idx,
                              type: "text",
                              disabled: "",
                            },
                            domProps: { value: vo.cntrSzCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "cntrSzCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "span",
                { staticStyle: { "font-weight": "bold", color: "red" } },
                [_vm._v("* 추가선적명세는 광양 반출건에만 기재")]
              ),
            ]),
            _c("div", { staticClass: "text_center mt10" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cntrReqInsert()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.062")))]
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.reqTempSaveYn === "Y",
                      expression: "reqTempSaveYn === 'Y'",
                    },
                  ],
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      return _vm.cntrReqDelete()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("art.CMBA660.007")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button gray lg",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      return _vm.reqCntrReqSave()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("js.booking.044")))]
              ),
              _vm._v("  "),
              _c(
                "a",
                {
                  staticClass: "button gray lg",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      _vm.$emit("close")()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.057")))]
              ),
            ]),
            _c(
              "win-layer-pop",
              { staticClass: "popup_dim" },
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.uploadParam },
                      on: {
                        close: _vm.closePopup,
                        "set-dext-file-info": _vm.arrangeUploadParam,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "text_center" }, [_vm._v(" 수신 ")]),
      _c("td", { attrs: { colspan: "2" } }, [_vm._v("고려해운(주)")]),
      _c("td"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }