var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "900px", height: "410px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "form",
        {
          attrs: { id: "frm_req_pop" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popup_cont cod_cont_area" },
            [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P095.001")) + " "),
                _c(
                  "span",
                  { staticClass: "position_relative screen-to-pdf-hide" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon help",
                        on: {
                          mouseover: function ($event) {
                            return _vm.showToolTip()
                          },
                          mouseleave: function ($event) {
                            return _vm.showToolTip()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.057")))]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.toolTipActive,
                            expression: "toolTipActive",
                          },
                        ],
                        staticClass: "tooltip_wrap short position_absolute",
                        staticStyle: { width: "360px", left: "0", top: "28px" },
                      },
                      [
                        _c("div", { staticClass: "cont" }, [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.001"))),
                          ]),
                          _c("ul", { staticClass: "bul_list_sm t2" }, [
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.ONEX010P095.002")) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.ONEX010P095.003")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm.delegateYn === "Y"
                ? _c("div", { staticClass: "content_box" }, [
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(0),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.004"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      _vm.nowDate,
                                      "."
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.005"))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.006"))),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.007"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " + _vm._s(_vm.bookingInfo.shprCstNm) + " "
                              ),
                            ]),
                            _c("div", { staticClass: "tbl_form mt5" }, [
                              _c("div", [
                                _c("span", { staticClass: "dv col_1" }, [
                                  _vm._v(_vm._s(_vm.formData.reqPicNm)),
                                ]),
                                _c("span", { staticClass: "dv col_2" }, [
                                  _vm._v(_vm._s(_vm.formData.reqPicTelNo)),
                                ]),
                                _c("span", { staticClass: "dv col_3" }, [
                                  _vm._v(_vm._s(_vm.formData.reqPicEmlAddr)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.008"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.bookingInfo.actShprCstEnm) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.delegateYn === "Y"
                ? _c("div", { staticClass: "content_box mt10 cod_cont_area" }, [
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(1),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.009"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(" " + _vm._s(_vm.bookingInfo.bkgNo) + " "),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.010"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.polPlcNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.podPlcNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_4" }),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.011"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.vslNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.voyNo) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_4" }),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("msg.ONEX010P095.012")) +
                                " " +
                                _vm._s(_vm.$t("msg.ONEX010P095.013"))
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _vm._l(
                                _vm.formData.containerList,
                                function (vo, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: "cntr_" + idx,
                                      staticClass:
                                        "tbl_form div_cntr_area screen-to-pdf-hide",
                                      attrs: { id: "alertArea_" + idx },
                                    },
                                    [
                                      _c("span", { staticClass: "dv col_5" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: vo.cntrNo,
                                              expression: "vo.cntrNo",
                                            },
                                          ],
                                          attrs: {
                                            id: "cntrNo_" + idx,
                                            type: "text",
                                            placeholder:
                                              "반출하고자 하는 컨테이너 번호",
                                          },
                                          domProps: { value: vo.cntrNo },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  vo,
                                                  "cntrNo",
                                                  $event.target.value
                                                )
                                              },
                                              function ($event) {
                                                return _vm.checkValidationCntrNo(
                                                  idx
                                                )
                                              },
                                            ],
                                            blur: function ($event) {
                                              return _vm.containerChk(idx)
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("span", { staticClass: "dv" }, [
                                        idx === 0
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "tbl_icon plus",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addContainer()
                                                  },
                                                },
                                              },
                                              [_vm._v(" plus ")]
                                            )
                                          : _vm._e(),
                                        idx !== 0
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "tbl_icon minus",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeContainer(
                                                      idx
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" minus ")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("span", { staticClass: "dv col_1" }, [
                                        _vm._v("From"),
                                      ]),
                                      _c("span", { staticClass: "dv col_3" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: vo.trmlNm,
                                              expression: "vo.trmlNm",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "fromTerminal_" + idx,
                                            placeholder: "From - Terminal",
                                            readonly: "",
                                          },
                                          domProps: { value: vo.trmlNm },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                vo,
                                                "trmlNm",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.formData.containerList,
                                function (vo, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: "cntr_" + idx,
                                      staticClass:
                                        "tbl_form div_cntr_area screen-to-pdf-show",
                                      attrs: { id: "alertArea_" + idx },
                                    },
                                    [
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "dv col_2" },
                                          [_vm._v(_vm._s(vo.cntrNo))]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "dv col_3" },
                                          [_vm._v(_vm._s(vo.trmlNm))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.015"))),
                          ]),
                          _c(
                            "td",
                            { staticClass: "screen-to-pdf-hide" },
                            [
                              _c("e-date-picker", {
                                attrs: { id: "coDt" },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationInputAll()
                                  },
                                },
                                model: {
                                  value: _vm.formData.coDt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "coDt", $$v)
                                  },
                                  expression: "formData.coDt",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", { staticClass: "screen-to-pdf-show" }, [
                            _c("p", [_vm._v(_vm._s(_vm.formData.coDt))]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.017"))),
                          ]),
                          _c("td", { staticClass: "screen-to-pdf-hide" }, [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.tfcoNm,
                                      expression: "formData.tfcoNm",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "tfcoNm",
                                    placeholder: "업체명",
                                  },
                                  domProps: { value: _vm.formData.tfcoNm },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.formData,
                                          "tfcoNm",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationInputAll()
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.tfcoPicNm,
                                      expression: "formData.tfcoPicNm",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "tfcoPicNm",
                                    placeholder: "성명",
                                  },
                                  domProps: { value: _vm.formData.tfcoPicNm },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.formData,
                                          "tfcoPicNm",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationInputAll()
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "dv" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.tfcoTelNo,
                                      expression: "formData.tfcoTelNo",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "tfcoTelNo",
                                    placeholder: "전화번호",
                                  },
                                  domProps: { value: _vm.formData.tfcoTelNo },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.formData,
                                          "tfcoTelNo",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationInputAll()
                                      },
                                    ],
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("td", { staticClass: "screen-to-pdf-show" }, [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(_vm._s(_vm.formData.tfcoNm)),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(_vm._s(_vm.formData.tfcoPicNm)),
                              ]),
                              _c("span", { staticClass: "dv" }, [
                                _vm._v(_vm._s(_vm.formData.tfcoTelNo)),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.018"))),
                          ]),
                          _c("td", { staticClass: "screen-to-pdf-hide" }, [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.updtShprCstNm,
                                      expression: "formData.updtShprCstNm",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "updtShprCstNm",
                                    placeholder: "화주 또는 운송사",
                                  },
                                  domProps: {
                                    value: _vm.formData.updtShprCstNm,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.formData,
                                          "updtShprCstNm",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationInputAll()
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.shprPicNm,
                                      expression: "formData.shprPicNm",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "shprPicNm",
                                    placeholder: "담당자 성명",
                                  },
                                  domProps: { value: _vm.formData.shprPicNm },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.formData,
                                          "shprPicNm",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationInputAll()
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "dv" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.shprPicTelNo,
                                      expression: "formData.shprPicTelNo",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "shprPicTelNo",
                                    placeholder: "담당자 전화번호",
                                  },
                                  domProps: {
                                    value: _vm.formData.shprPicTelNo,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.formData,
                                          "shprPicTelNo",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationInputAll()
                                      },
                                    ],
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("td", { staticClass: "screen-to-pdf-show" }, [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(_vm._s(_vm.formData.updtShprCstNm)),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(_vm._s(_vm.formData.shprPicNm)),
                              ]),
                              _c("span", { staticClass: "dv" }, [
                                _vm._v(_vm._s(_vm.formData.shprPicTelNo)),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.014"))),
                          ]),
                          _c("td", { staticClass: "screen-to-pdf-hide" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formData.reqRsnCont,
                                  expression: "formData.reqRsnCont",
                                },
                              ],
                              attrs: { type: "text", placeholder: "반출 사유" },
                              domProps: { value: _vm.formData.reqRsnCont },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.formData,
                                    "reqRsnCont",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("td", { staticClass: "screen-to-pdf-show" }, [
                            _c("p", [_vm._v(_vm._s(_vm.formData.reqRsnCont))]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm.terminalFlag === "Y"
                      ? _c("table", { staticClass: "tbl_col mt10" }, [
                          _vm._m(2),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text_left",
                                  attrs: { rowspan: "2" },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.019")))]
                              ),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.020"))),
                              ]),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.021"))),
                              ]),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.022"))),
                              ]),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.023"))),
                              ]),
                            ]),
                            _c("tr", { staticClass: "screen-to-pdf-hide" }, [
                              _c("td", { staticClass: "border_left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reqRsnCont1,
                                      expression: "reqRsnCont1",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "reqRsnCont1",
                                    placeholder: "품명 기재",
                                  },
                                  domProps: { value: _vm.reqRsnCont1 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.reqRsnCont1 = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reqRsnCont2,
                                      expression: "reqRsnCont2",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "reqRsnCont2",
                                    placeholder: "수량 기재",
                                  },
                                  domProps: { value: _vm.reqRsnCont2 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.reqRsnCont2 = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reqRsnCont3,
                                      expression: "reqRsnCont3",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "reqRsnCont3",
                                    placeholder: "중량 기재",
                                  },
                                  domProps: { value: _vm.reqRsnCont3 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.reqRsnCont3 = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reqRsnCont4,
                                      expression: "reqRsnCont4",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "reqRsnCont4",
                                    placeholder: "화물가액 기재",
                                  },
                                  domProps: { value: _vm.reqRsnCont4 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.reqRsnCont4 = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("tr", { staticClass: "screen-to-pdf-show" }, [
                              _c("span", { staticClass: "dv col_2" }, [
                                _vm._v(_vm._s(_vm.reqRsnCont1)),
                              ]),
                              _c("span", { staticClass: "dv col_2" }, [
                                _vm._v(_vm._s(_vm.reqRsnCont2)),
                              ]),
                              _c("span", { staticClass: "dv col_2" }, [
                                _vm._v(_vm._s(_vm.reqRsnCont3)),
                              ]),
                              _c("span", { staticClass: "dv" }, [
                                _vm._v(_vm._s(_vm.reqRsnCont4)),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "normal_box" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.024")))]),
                      _c("p", { staticClass: "text_center mt10" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P095.025")) + " "
                        ),
                      ]),
                      _c("ol", [
                        _c("li", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.026"))),
                        ]),
                        _c("li", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.027"))),
                        ]),
                        _c("li", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.028"))),
                        ]),
                        _c("li", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.029"))),
                        ]),
                        _c("li", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.030"))),
                        ]),
                      ]),
                      _c("p", { staticClass: "text_center mt10" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$ekmtcCommon.changeDatePattern(_vm.nowDate, ".")
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "text_center mt10" }, [
                        _c("span", { staticClass: "screen-to-pdf-hide" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.agreeYn,
                                expression: "agreeYn",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              name: "no",
                              id: "chk1",
                              "true-value": "Y",
                              "false-value": "N",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.agreeYn)
                                ? _vm._i(_vm.agreeYn, null) > -1
                                : _vm._q(_vm.agreeYn, "Y"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.agreeYn,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.agreeYn = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.agreeYn = $$c
                                }
                              },
                            },
                          }),
                          _vm._m(3),
                        ]),
                        _vm._m(4),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P095.031")) + " ("
                        ),
                        _c("span", { staticClass: "ml10 mr10" }, [
                          _vm._v(_vm._s(_vm.bookingInfo.actShprCstEnm)),
                        ]),
                        _vm._v(
                          ")" + _vm._s(_vm.$t("msg.ONEX010P095.032")) + " "
                        ),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "txt_desc mt10",
                          staticStyle: { background: "none" },
                        },
                        [
                          _c("span", { staticClass: "font_medium" }, [
                            _vm._v(
                              "[" + _vm._s(_vm.$t("msg.ONEX010P095.033")) + "]"
                            ),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX010P095.034")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX010P095.036"))
                          ),
                          _c("br"),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "34px" } },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.037")))]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX010P095.038")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX010P095.039"))
                          ),
                          _c("br"),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "34px" } },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.040")))]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX010P095.041")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX010P095.042"))
                          ),
                          _c("br"),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "34px" } },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.043")))]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX010P095.044")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX010P095.045")) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.delegateYn === "N"
                ? _c("div", { staticClass: "content_box" }, [
                    !(
                      _vm.memberDetail.userCtrCd === "KR" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                    )
                      ? _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX010P095.047")) + " "
                          ),
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "mt5" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.memberDetail.userCtrCd !== "KR",
                              expression: "memberDetail.userCtrCd !== 'KR'",
                            },
                          ],
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "button sm",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.excelDownload(
                                    "ContainerOutRequest.xlsx"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.048")))]
                          ),
                        ]
                      ),
                      !(
                        _vm.memberDetail.userCtrCd === "KR" &&
                        _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                      )
                        ? _c("span", { staticClass: "ml5" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button sm",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.moveDelegateReqPage()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.049")))]
                            ),
                          ])
                        : _vm._e(),
                      !(
                        _vm.memberDetail.userCtrCd === "KR" &&
                        _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                      )
                        ? _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon help ml10",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.guidePop()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.050")))]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.051"))),
                    ]),
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(5),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.052"))),
                          ]),
                          _c("td", [
                            _vm.memberDetail.userCtrCd == "KR"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "filebox",
                                    attrs: { id: "file_att" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button sm gray file",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.openEleConsReqDocPop()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("js.booking.043")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "filebox",
                                    attrs: { id: "file_att" },
                                  },
                                  [
                                    _vm.uploadParam.length === 0
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "button sm gray file",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.uploadFilePop()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("msg.ONEX010P095.053")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.uploadParam.length > 0
                                      ? _c("div", [
                                          _c("span", {
                                            staticClass: "tbl_icon attach",
                                          }),
                                          _c(
                                            "a",
                                            { staticClass: "text_link" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.uploadParam[0]
                                                    .originalName
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "tbl_icon attdel",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeUploadFile()
                                                },
                                              },
                                            },
                                            [_vm._v("X")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                          ]),
                          _c("td", { staticClass: "border_left_none" }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "txt_desc mt10",
                        staticStyle: { background: "none" },
                      },
                      [
                        _c("span", { staticClass: "font_medium" }, [
                          _vm._v(
                            "[" + _vm._s(_vm.$t("msg.ONEX010P095.033")) + "]"
                          ),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P095.034")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                            " : " +
                            _vm._s(_vm.$t("msg.ONEX010P095.036"))
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { "margin-left": "34px" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.037"))),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P095.038")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                            " : " +
                            _vm._s(_vm.$t("msg.ONEX010P095.039"))
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { "margin-left": "34px" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.040"))),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P095.041")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                            " : " +
                            _vm._s(_vm.$t("msg.ONEX010P095.042"))
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { "margin-left": "34px" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P095.043"))),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P095.044")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.ONEX010P095.035")) +
                            " : " +
                            _vm._s(_vm.$t("msg.ONEX010P095.045")) +
                            " "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt10 text_right screen-to-pdf-hide" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.memberDetail.userCtrCd !== "KR",
                        expression: "memberDetail.userCtrCd !== 'KR'",
                      },
                    ],
                    staticClass: "button blue lg mr5",
                    on: {
                      click: function ($event) {
                        return _vm.reqSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.052")))]
                ),
                _vm.delegateYn === "Y"
                  ? _c(
                      "a",
                      {
                        staticClass: "button gray lg",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.057")))]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "button gray lg",
                        on: {
                          click: function ($event) {
                            return _vm.confirmClose()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.057")))]
                    ),
              ]),
              _vm.delegateYn === "Y"
                ? _c(
                    "win-layer-pop",
                    { staticClass: "popup_dim" },
                    [
                      _vm.customComponent
                        ? _c(_vm.customComponent, {
                            tag: "component",
                            attrs: { "parent-info": _vm.popParams },
                            on: { 1: _vm.selectFunc, close: _vm.closePopup },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.delegateYn === "N"
                ? [
                    _vm.memberDetail.userCtrCd == "KR"
                      ? _c(
                          "win-layer-pop",
                          { ref: "openPop", staticClass: "popup_dim_snd" },
                          [
                            _vm.popComponent
                              ? _c(_vm.popComponent, {
                                  tag: "component",
                                  attrs: { "parent-info": _vm.popParams },
                                  on: {
                                    close: _vm.closeCommonPopup,
                                    callback: _vm.popCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "win-layer-pop",
                          { ref: "uploadPop", staticClass: "popup_dim" },
                          [
                            _vm.customComponent
                              ? _c(_vm.customComponent, {
                                  tag: "component",
                                  attrs: {
                                    "parent-info": _vm.uploadParam,
                                    "single-file-yn": true,
                                  },
                                  on: {
                                    "set-dext-file-info":
                                      _vm.arrangeUploadParam,
                                    close: _vm.closePopup,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                  ]
                : _vm._e(),
              _vm.delegateYn === "N"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      attrs: { id: "guide_pop" },
                    },
                    [
                      _vm.customComponent
                        ? _c(_vm.customComponent, {
                            tag: "component",
                            on: { close: _vm.closePopup },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
      _c("col"),
      _c("col"),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk1" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "screen-to-pdf-show" }, [
      _c("input", {
        attrs: {
          checked: "checked",
          disabled: "disabled",
          type: "checkbox",
          name: "no",
          id: "chk1_temp",
        },
      }),
      _c("label", { attrs: { for: "chk1_temp" } }, [
        _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }